

/* From https://codepen.io/t_afif/pen/abKyJNJ Thanks Temani! Great Looking buttons! */
.App {
  height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  width: 100vw;
  /* background: #F2DCA2; */
  background-color: lavender;
}
button {
  font-family: system-ui, sans-serif;
  padding: .1em .6em;
  font-weight: bold;  
  border: none;
  background-color:  lightcoral; /* the color*/
  width: 315px;
  height: 80px;
  color: rgba(280,280,280,0.7);
  font-size: 32px;
  cursor: pointer;
}

button:hover {
  filter: brightness(110%) saturate(120%);
}

button:disabled:hover {
  filter: none;
}

.spelling-char {
  font-size: 3.5rem;
}

.spelling-char.incomplete {
  color: rgba(280,280,280,0.4)
}

.spelling-char.complete {
  color: rgba(280,280,280 )

}

button:disabled {
  cursor: default;
}





.cootie-catcher.closed .inner-flaps {
  visibility: hidden
}



.cootie-catcher{
  height: 200px;
  width: 200px;
}

.inner-flaps {
  height: 97%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height:  78%;
  width: 80%;
  position: relative;
  top: -178px;
  left: 20px;
  
}

.inner-flap {
  background-color: gray;
  height: 50%;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: end;
  font-size: 27px;
  color: rgba(300,300,300, 0.7);
}

.inner-number {
  padding-bottom: 7px;
  padding-right: 14px;
}

.inner-number.flip-text {
  padding-left: 14px;
}



.open.horizontal .inner-flap {
  -webkit-clip-path:polygon(0 100%, 100% 100%, 100% 10%);
}

.open.vertical .inner-flap {
  -webkit-clip-path:polygon(0 100%, 100% 100%, 100% 10%);
}

.outer-flaps {
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
  position: relative;
  height: 100%;
  z-index: 0;

}

.outer-flap {
  height: 50%;
  width: 50%;
   -webkit-clip-path:polygon(0 0, 100% 20%, 99% 99%, 20% 100%);
}

.open.horizontal .outer-flap {
  -webkit-clip-path: polygon(0% 100%, 10% 0%, 100% 30%, 20% 100%);
}

.open.vertical .outer-flap {
-webkit-clip-path: polygon(0% 20%, 100% 0%, 100% 30%, 20% 100%);
}

.outer-flap.top-left {
  background: darkorange  ;
}

.top-right {
  transform: scaleX(-1);
}

.outer-flap.top-right {
  background: dodgerblue;
}

.bottom-left {
  transform: scaleY(-1);
}

.outer-flap.bottom-left {
  background: limegreen;
}

.bottom-right {
  transform: scaleX(-1) scaleY(-1);
}

.outer-flap.bottom-right {
  background: violet;
}

.inner-flap.bottom-left, .inner-flap.top-right {
  background-color: silver;
}

.flip-text {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.clickable:hover {
  filter: brightness(110%) saturate(120%);
  cursor: pointer;
  z-index: 1000;
}

.fortune-text {
  font-size: 50px;
  margin: 20px;
}

.countdown-to-tomorrow {
  font-size: 13px;
  /* color: rgba(280,280,280,0.7); */
  margin-top: 6.6px;
}

h4 {
  font-size: 30px;
  margin: 10px;
}

.fortune-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(280,280,280);
  width: 600px;
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  padding-bottom: 40px;
}

.credit {
  position: absolute;
  bottom: 15px;
}
